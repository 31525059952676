import { Box, Container, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import styles from './VendingItemsModal.module.css'
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
const snackList = [ "Snickers Bars", "Pop Tarts", "Reese's Peanut Butter Cups", "Snyder's Pretzels", "M&Ms", "Oreos", "Hershey Bars", "Cheetos", "Twix", "Milky Way", "Skittles", "Doritos", "Cookies", "Twizzlers", "Clif Bar", "Kit Kat"];
const drinkList = [ "Bottled Water", "Coca-Cola", "Pepsi", "Sprite", "Gatorade", "Iced Coffee", "Iced Tea", "Lemonade", "Mountain Dew", "Dr. Pepper", "Red Bull", "Fanta"];

const VendingItemsModal = (props) => {
    const theme = createTheme();
    const {
        showSnacks = props.showSnacks,
        closeModal = props.closeModal
    } = props

    const showVendingItems = () => {
        let list = [];
        if (showSnacks) {
            list = snackList;
        } else {
            list = drinkList
        }
        return list.map((item, index) => 
            <Box className={styles.vendingItemsList} sx={{marginLeft:"5%"}} width="60%" display="flex" alignItems="center"  key={index}>
                <HorizontalRuleIcon/>
                <Typography pl={2} variant="h5" fontFamily="Belanosima" pb={1}>
                    {item}
                </Typography> 
            </Box>
        )
    }
    theme.typography.h2 = {
        [theme.breakpoints.up('xs')]: {
          fontSize: '2.5rem',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4rem',
        },
        '@media (max-width:450px)': {
          fontSize: '2rem',
        },
    };

    theme.typography.h5 = {
        [theme.breakpoints.up('xs')]: {
          fontSize: '1.4rem',
        },
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.4rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        },
        '@media (max-width:450px)': {
          fontSize: '1.2rem',
        },
    };
    return(
        <ThemeProvider theme={theme}>
            <Box className={styles.vendingItemscontainer} onClick= {() => closeModal()}>
                <Box width={{xs:'80%', sm:'60%',md:'45%', lg:'40%', xl:'40%'}} border=".25rem solid black" minHeight="70%" overflow="scroll" maxHeight="80%" borderRadius="1rem" bgcolor="white" onClick={(e) => e.stopPropagation()}>
                    <Box display="flex" position="sticky" top="0" zIndex="1" bgcolor="#ab20fd" mb={2}>
                        <Typography variant="h2" color="white" textAlign="center" fontFamily="Belanosima" borderBottom=".25rem solid black" width="100%" pt={2} pb={2}>
                            {showSnacks ? "Snack Items": "Drink Items" }
                        </Typography>
                        <Box className={styles.closeModalIcon}>
                            <IconButton onClick= {() => closeModal()} sx={{fontSize:"inherit", color:'red'}}>
                                <CloseIcon fontSize="8rem"/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Container>
                        <Box display="flex" sx={{justifyContent:'center'}}>
                            <Typography variant="a" textAlign="center" fontSize="1.25rem" color="#ff8149" fontWeight="bolder" pr={1}>*</Typography>
                            <Typography variant="a" textAlign="center" color="#ff8149" fontWeight="bolder">Items Are Subject To Change</Typography>
                        </Box>
                        <Stack display="flex" alignItems="center" width="100%">
                            {showVendingItems()}
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}


export default VendingItemsModal
