import { Box, Typography, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BuildIcon from '@mui/icons-material/Build';
import "./Carousel.css"
const Carousel = ({images}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setCurrentImageIndex(prevIndex => {
                return prevIndex + 1 === images.length ? 0 : prevIndex + 1;
            })
        }, 10000)
        return () => {
            clearInterval(timerInterval);
        }
    }, [images.length])
    return (
        <Box className="container-slide">
            {images.map((image, index) =>
                <Box zIndex={0} key= {index} className={currentImageIndex === index ? "active-anim slide" : "slide"}>
                  <img src={process.env.PUBLIC_URL + "/Images/" + image.url}  alt={image.title}/>
                </Box>
            )}
            <Box zIndex={1} sx={{height:'100%', width:'100%', backgroundColor:'black', position: 'absolute', color:'white', opacity: '.65'}}>
            </Box>
            <Box zIndex={3} sx={{position: 'absolute', height:'100%', display: 'flex', alignItems:'center'}}>
                <Box width={{xs:'100%', sm:'80%', md:'60%'}} p={3}>
                    <Typography variant="h3" color='#ab20fd' fontWeight='bolder' textAlign={{xs:'center', sm:'start'}}>Next Level Vending</Typography>
                    <Typography variant="h4" color='#ADD8E6' fontWeight='bolder' textAlign={{xs:'center', sm:'start'}} mb={1}>Efficient and Timely Service</Typography>
                    <Box display="flex" justifyContent={{xs:'center', sm:'start'}} width={{xs:"100%"}}>
                        <Box  width={{xs:'80%', md:'100%'}}>
                        <Typography color='white' fontWeight='bolder' textAlign={{xs:'center', sm:'start'}}>TwoBrothersVending LLC is here to make a change to the vending machine industry by creating a tech ecosystem to provide premium service to our clients</Typography>
                        </Box>
                    </Box>
                </Box>
                <Stack direction='row' position='absolute' bottom='5%' display={{xs:'none',sm:'flex'}} width='100%'>
                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-evenly" width="100%">
                            <Box display='flex' alignItems="center" mb={1}>
                                <ThumbUpAltIcon htmlColor="#fcbe11" fontSize="large"/>
                                <Typography color='#fcbe11' fontFamily='Acme' pl={1}>Trustworthy</Typography>
                            </Box>
                            <Box display='flex' mb={1} alignItems="center">
                                <EmojiPeopleIcon htmlColor="#fcbe11" fontSize="large"/>
                                <Typography color='#fcbe11' fontFamily='Acme' pl={1}>Reliable</Typography>
                            </Box>
                            <Box display='flex' mb={1} alignItems="center">
                                <BuildIcon fontSize="large" htmlColor="#fcbe11"/>
                                <Typography color='#fcbe11' fontFamily='Acme' pl={1}>Built To Last</Typography>
                            </Box>
                        </Stack>
                    </Stack>
            </Box>
        </Box>
    )
}
export default Carousel;