import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import React from "react"
import './Footer.css'
const theme = createTheme();
theme.typography.body1={
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
    },
    '@media (min-width:1536px)': {
      fontSize: '1.5rem',
    },
}
const Footer = () => {
    return(
        <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="center" position="relative">
                <Box className="footer" borderRadius=".4rem" bgcolor='#ab20fd' width={{xs:'100%', sm: '95%'}} pt={2} pb={2}>
                    <Box display='flex' pl={1} justifyContent="center">
                        <EmailIcon htmlColor="white"/>
                        <Typography color='white' fontWeight='bolder' pl={1} fontFamily="Belanosima">
                            Email Us: info@twobrothersvendingllc.com
                        </Typography>
                    </Box>
                </Box>
            </Box>  
        </ThemeProvider>
        
    )
}
export default Footer