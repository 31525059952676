import { ThemeProvider } from "@emotion/react";
import "./VendingItemsPage.css"

import { Box, Stack, Typography, createTheme} from "@mui/material";
import React, { useState } from "react";
import VendingItemsModal from "../VendingModal/VendingItemsModal";
const theme = createTheme();

theme.typography.h1 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.6rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '5rem',
    },
    '@media (max-width:450px)': {
      fontSize: '1.5rem',
    },
};

theme.typography.h6 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
};

theme.typography.h2 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.15rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '3.5rem',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '3.75rem',
    },
};

const VendingItemPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [showSnacks, setShowSnacks] = useState(null);

    const handleVendingSelection = (getSnacks) => {
        if (getSnacks) {
            setShowSnacks(true);
        } else {
            console.log("Get Drinks")
            setShowSnacks(false);
        }
        setOpenModal(true);
    }
    return (
        <ThemeProvider theme={theme}>
                <Box className="vending-items-container">
                    <Box width="80%" height="90%" mt={8}>
                        <Typography textAlign="center" variant="h2" fontFamily="Belanosima">View Our Vending Items Inventory</Typography>
                        <Stack height="90%">
                            <Box m={2} className="snack-background" height="35%" display="flex" justifyContent="center" alignItems="center" position="relative" onClick={() => handleVendingSelection(true)}>
                                <Typography fontFamily="Caprasimo" className="wording-overlay" variant="h1" width={{xs:'100%', sm:'60%'}}>Snack Items</Typography>
                                <Box className="overlay"></Box>
                            </Box>
                            <Box m={2} className="drink-background" height="35%" display="flex" justifyContent="center" alignItems="center" position="relative" onClick={() => handleVendingSelection(false)}>
                                <Typography fontFamily="Caprasimo" className="wording-overlay" variant="h1">Drink Items</Typography>
                                <Box className="overlay"></Box>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                {openModal && <VendingItemsModal showSnacks={showSnacks} closeModal={() => setOpenModal(false)}/>}
        </ThemeProvider>
    )
}
export default VendingItemPage