import { Box, IconButton, Typography, ThemeProvider, createTheme, Stack, AppBar, Toolbar, Container} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react"
import './Menu.css'
import { NavLink, useNavigate } from "react-router-dom";

const pages = [{title: 'Vending Items', link: '/Vending'}, {title: 'About Us', link: '/About'}];
const theme = createTheme();
theme.typography.h3 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
    '@media (max-width:400px)': {
        fontSize: '1.3rem',
    },
};

theme.typography.h6 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
};
const Menu = () => {
    const [dropdownOpen, setDropDownOpen] = useState(false);
    let navigate = useNavigate();
    const [windowSize, setWindowSize] = useState( window.innerWidth);
    
    useEffect(() => {
    const handleWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    

    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
    useEffect(() => {
        if (windowSize > 900) {
            setDropDownOpen(false);
        }
    },[windowSize]);
    const dropdownListHandler = (link) => {
        setDropDownOpen(false);
        if (link === null) {
            navigate("/")
        }
        else {
            navigate(link); 
        }
        
    }
    const listItems = pages.map((d, index) => 
        <NavLink to={d.link} className="dropdown-link" key={index}>
           <Box onClick={() => dropdownListHandler(d.link)}>
                <Typography variant="h6" color="inherit" textAlign='center' fontFamily='Acme'>
                    {d.title}
                </Typography>
            </Box> 
        </NavLink>
        
        );

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="sticky" sx={{bgcolor:'white'}}>
                <Container maxWidth="false">
                    <Stack>
                        <Toolbar disableGutters>
                                <Typography
                                    className="brand-icon"
                                    onClick={() => dropdownListHandler(null)}
                                    variant="h3"
                                    noWrap
                                    component="a"
                                    sx={{
                                    cursor:'pointer',
                                    mr: 2,
                                    flex: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'Acme',
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color: 'black',
                                    textDecoration: 'none',
                                    }}
                                >
                                    TwoBrothersVending
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={() => setDropDownOpen(!dropdownOpen)}
                                        sx={{color: dropdownOpen ? 'red' : 'black'}}
                                        color="purple" >
                                        {dropdownOpen ? <CloseIcon color="red"/>: <MenuIcon color="red"/>}
                                    </IconButton>
                                </Box>
                                <Typography
                                    className="brand-icon"
                                    variant="h3"
                                    noWrap
                                    component="a"
                                    onClick={() => dropdownListHandler(null)}
                                    sx={{
                                    mr: 2,
                                    cursor:'pointer',
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'Acme',
                                    fontWeight: 700,
                                    letterSpacing: '.1em',
                                    color: 'black',
                                    textDecoration: 'none',
                                    }}>
                                    TwoBrothersVending
                                </Typography>
                                <Box sx={{ flexGrow: 1, justifyContent:"end", display: { xs: 'none', md: 'flex' } }}>
                                    <Box display="flex">
                                        {pages.map((page,index) => (
                                            <NavLink to={page.link} className="menu-link" key={index}>
                                                <Typography variant="h6" fontFamily='Acme' fontWeight='bold' color="inherit">
                                                    {page.title}
                                                </Typography>
                                            </NavLink>
                                        ))}
                                    </Box>
                                </Box>
                        </Toolbar>
                    </Stack>
                </Container>
                <Box position="relative">
                    {dropdownOpen && <Stack sx = {{display: { xs: 'flex', md: 'none', position:'absolute', width:"100vw" }}}>
                        {listItems}
                    </Stack>}
                </Box>
            </AppBar>
        </ThemeProvider>
    )
}
export default Menu