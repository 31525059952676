import React from "react";
import { Box, createTheme, Stack, ThemeProvider, Typography } from "@mui/material";
import './WelcomePage.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageSlider from "../Carousel/Carousel";

const theme = createTheme();

const imageArray = [
  {url: "snack-banner-2.jpeg", title: 'Snacks'},
  {url: "coke-bottles.webp", title: 'Coke Bottles'},
  {url: "vending-snacks.jpg", title: 'Vending Snacks'},
]

theme.typography.h3 = {
  [theme.breakpoints.up('xs')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '6rem',
  },
  fontSize: '2rem',
  '@media (max-width:500px)': {
    fontSize: '2.4rem',
  },
  '@media (max-width:400px)': {
    fontSize: '1.75rem',
  },
  '@media (min-width:1536px)': {
    fontSize: '4.5rem',
  },
};
theme.typography.body1={
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.45rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '2.5rem',
      },
    '@media (max-width:550px)': {
      fontSize: '1rem',
    },
    fontSize: '2rem',
    '@media (max-width:400px)': {
      fontSize: '.95rem',
    },
    '@media (min-width:1536px)': {
      fontSize: '1.5rem',
    },
}

theme.typography.h4 = {
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'rem',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '5rem',
    },
    fontSize: '2rem',
    '@media (max-width:500px)': {
      fontSize: '1.5rem',
    },
    '@media (max-width:400px)': {
      fontSize: '1.15rem',
    },
    '@media (min-width:1536px)': {
      fontSize: '3.5rem',
    },
  };

  theme.typography.h5 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.4rem',
      },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  };

const WelcomePage = () => {
    return(
        <ThemeProvider theme={theme}>
            <Box>
                <ImageSlider images={imageArray}/>
                <Typography className="test" variant="h3" fontFamily='Acme' textAlign='center' mt={1}>Why Us?</Typography>
                <Stack direction={{ md: 'column', lg: 'row' }}display='flex' mb={8}>
                    <Box flex={1} bgcolor="#fafafa" height="40vh" m={2} display="flex" alignItems="center" p={4}>
                        <Stack flexDirection="vertical" width="100%">
                            <Stack>
                                <Typography  textAlign="center" variant="h5" color='#ab20fd' fontWeight='bolder' fontFamily='Acme' pb={1}>Commited To Our Clients</Typography>
                            </Stack>
                            <Box width="80%" margin="0 auto">
                                <Stack flexDirection="vertical">
                                <Box display="flex" pb={2}>
                                    <CheckCircleIcon />
                                    <Typography pl={1} fontWeight='bolder'>Leverage QR codes to key in on products customers want</Typography>
                                </Box>
                                <Box display="flex" pb={2}>
                                    <CheckCircleIcon />
                                    <Typography pl={1} fontWeight='bolder'>We Service Specific Areas In Maryland To Ensure Dependability</Typography>
                                </Box>
                            </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <Box flex={1} bgcolor="#fafafa" height="40vh" m={2} display="flex" alignItems="center" p={4}>
                        <Stack flexDirection="vertical" width="100%">
                            <Stack>
                                <Typography textAlign="center" variant="h5" color='#ab20fd' fontWeight='bolder' fontFamily='Acme' pb={1}>Quality Service</Typography>
                            </Stack>
                            <Box width="80%" margin="0 auto">
                                <Stack flexDirection="vertical">
                                <Box display="flex" pb={2}>
                                    <CheckCircleIcon />
                                    <Typography pl={1} fontWeight='bolder'>Our Machines Use Guaranteed Vend Technology</Typography>
                                </Box>
                                <Box display="flex" pb={2}>
                                    <CheckCircleIcon />
                                    <Typography pl={1} fontWeight='bolder'>Our Machines Come From Reputable Vendors And Distributors</Typography>
                                </Box>
                            </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </ThemeProvider>
    )
}
export default WelcomePage;