import { Box, Stack, ThemeProvider, Typography, createTheme, Container } from "@mui/material";
import styles from './About.module.css'
import React from "react";
const theme = createTheme();
theme.typography.h1 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '6rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '7rem',
    },
    '@media (max-width:450px)': {
      fontSize: '2.75rem',
    },
};

theme.typography.h4 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5em',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.75rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem',
    },
    '@media (max-width:450px)': {
      fontSize: '1.25rem',
    },
};

theme.typography.body1 = {
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.25em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
    fontFamily: 'Belanosima',
    '@media (max-width:425px)': {
      fontSize: '1.1rem',
    },
};

const AboutPage = () => {
    return(
        <ThemeProvider theme={theme}>
            <Box position="relative" mb={4}>
                <Box className={styles.aboutUsBackground} sx={{p:'2rem 0rem'}}>
                    <Container maxWidth="xl">
                        <Typography textAlign={{xs:'center', sm:'start'}} variant="h1" fontWeight="bolder" fontFamily="Belanosima" color="#ab20fd">
                            Who Are We?
                        </Typography>
                        <Box width={{xs:'85%', sm: '60%'}}  justifyContent="center" margin={{xs:'0 auto', sm: '0'}}>
                        <Typography textAlign={{xs:'center', sm:'start'}} color="white" variant="h4" fontFamily="Belanosima" fontSize="2rem" fontWeight="bolder">
                                We are a minority owned company here to provide a more refined approach to the vending machine business
                            </Typography> 
                        </Box>
                    </Container>
                </Box>
                <Box bgcolor="rgba(0,0,0,.6)" position='absolute' top='0' width="100vw" sx={{p:'2rem 0rem'}}>
                    <Container maxWidth="xl">
                        <Typography textAlign={{xs:'center', sm:'start'}} variant="h1" fontWeight="bolder" fontFamily="Belanosima" color="#ab20fd">
                            Who Are We?
                        </Typography>
                        <Box width={{xs:'85%', sm: '60%'}} justifyContent="center" margin={{xs:'0 auto', sm: '0'}}>
                           <Typography textAlign={{xs:'center', sm:'start'}} color="white" variant="h4" fontFamily="Belanosima" fontSize="2rem" fontWeight="bolder">
                                We are a minority owned company here to provide a more refined approach to the vending machine business
                            </Typography> 
                        </Box>
                    </Container>
                </Box>
            </Box>
            <Stack className={styles.descriptionContainer} mb={8}>
                <Box className={styles.subDescriptionContainer} mb={4}>
                    <Container>
                        <Typography textAlign={{xs:'center', sm:'start'}} color="#ab20fd" fontFamily="Belanosima" variant="h4" fontWeight="bolder" mb={1}>Win Win</Typography>
                        <Typography fontFamily="Belanosima" variant="body1" fontSize="1rem">
                            A Vending Machine Business isn't just about installing vending machines. It's about providing a quality service to the clients in the locations in which we operate to ensure everyone is satisfied. We follow a simple motto, WIN WIN! We get to install a vending machine and our clients receive the snacks and drinks they want. Simple!
                        </Typography>
                    </Container>
                </Box>
                <Box className={styles.subDescriptionContainer} mb={4}>
                    <Container>
                        <Typography textAlign={{xs:'center', sm:'start'}} color="#ab20fd" fontFamily="Belanosima" variant="h4" fontWeight="bolder" mb={1}>Innovation</Typography>
                        <Typography fontFamily="Belanosima" variant="body1" fontSize="1rem">
                            At TwoBrothersVending we strive to make sure our vending machines are at the forefront of innovation in the industry. That's why our machines incorporate the latest trends such as QR codes, credit card readers, and guaranteed vend sensor technology to make our machines as accessible as possible and give our customers ways to interact with us.
                        </Typography>
                    </Container>
                </Box>
                <Box className={styles.subDescriptionContainer} mb={4}>
                    <Container>
                        <Typography textAlign={{xs:'center', sm:'start'}} color="#ab20fd" fontFamily="Belanosima" variant="h4" fontWeight="bolder" mb={1}>Quality</Typography>
                        <Typography fontFamily="Belanosima" variant="body1">
                            'Quality Over Quantity' is the cornerstone of how we do business. We proritize placing vending machines in specific locations that we can focus on and service quickly rather than placing vending machines anywhere we can.
                        </Typography>
                    </Container>
                </Box>
            </Stack>
        </ThemeProvider>
    )
}
export default AboutPage