import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WelcomePage from './Components/WelcomePage/WelcomePage';
import Menu from './Components/Navbar/Menu';
import AboutPage from './Components/AboutPage/About';
import VendingItemPage from './Components/VendingItemsPage/VendingItemsPage';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <Menu/>
      <Routes>
        <Route exact path='/' element={<WelcomePage/>}/>
        <Route path='/About' element={<AboutPage/>}/>
        <Route path='/Vending' element={<VendingItemPage/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
